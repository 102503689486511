@use 'src/assets/styles/app';

// Redefining the vars at router level allow us to override them (specifically the ones used in calc)
:root,
.router {
  // Breakpoints
  --x-small: 720px;
  --small: 1024px;
  --medium: 1280px;
  --large: 1440px;
  --x-large: 1920px;

  // Caps
  --max-padding: 135px;

  // Sizings
  --push-height: 34px;
  --nav-height: 113px;
  --header-height: calc(var(--push-height) + var(--nav-height));
  --account-height: 0px;
  --aside-width: 380px;
  --layout-width: calc(100vw - var(--layout-padding) * 2);
  --layout-padding: calc(
    (100vw - (var(--x-large) - (var(--max-padding) * 2))) / 2
  );
  --layout-gap: 65px;

  @include app.device('x-large') {
    --layout-padding: var(--max-padding);
  }

  @include app.device('large') {
    --layout-padding: 80px;
    --layout-gap: 58px;
  }

  @include app.device('medium') {
    --layout-padding: 50px;
  }

  @include app.device('small') {
    --layout-padding: 40px;
    --nav-height: 82px;
    --account-height: 67px;
    --title-gap: 40px;
  }

  @include app.device('x-small') {
    --layout-padding: 24px;
  }
}
