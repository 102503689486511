@use 'src/assets/styles/app';

:root {
  // heading-1
  --h1-font-size: 68px;
  --h1-line-height: 64px;
  --h1-font-weight: 750;
  --h1-letter-spacing: -2px;
  --h1-font-style: italic;
  --h1-text-transform: uppercase;

  // heading-2
  --h2-font-size: 34px;
  --h2-line-height: 38px;
  --h2-font-weight: 750;
  --h2-letter-spacing: -0.5px;
  --h2-font-style: italic;
  --h2-text-transform: uppercase;

  // heading-3
  --h3-font-size: 26px;
  --h3-line-height: 30px;
  --h3-font-weight: 700;
  --h3-letter-spacing: normal;
  --h3-font-style: normal;
  --h3-text-transform: none;

  // heading-4
  --h4-font-size: 24px;
  --h4-line-height: 28px;
  --h4-font-weight: 500;
  --h4-letter-spacing: normal;
  --h4-font-style: normal;
  --h4-text-transform: none;

  // heading-5
  --h5-font-size: 20px;
  --h5-line-height: 24px;
  --h5-font-weight: 700;
  --h5-letter-spacing: normal;
  --h5-font-style: normal;
  --h5-text-transform: none;

  // heading-6
  --h6-font-size: 20px;
  --h6-line-height: 24px;
  --h6-font-weight: 700;
  --h6-letter-spacing: 0.15px;
  --h6-font-style: normal;
  --h6-text-transform: none;

  // heading-7
  --h7-font-size: 20px;
  --h7-line-height: 24px;
  --h7-font-weight: 750;
  --h7-letter-spacing: 0;
  --h7-font-style: italic;
  --h7-text-transform: uppercase;

  // body-1
  --body1-font-size: 18px;
  --body1-line-height: 25px;
  --body1-font-weight: 500;
  --body1-letter-spacing: normal;
  --body1-font-style: normal;
  --body1-text-transform: none;

  // body-2
  --body2-font-size: 14px;
  --body2-line-height: 24px;
  --body2-font-weight: 500;
  --body2-letter-spacing: normal;
  --body2-font-style: normal;
  --body2-text-transform: none;

  // subtitle-1
  --sub1-font-size: 14px;
  --sub1-line-height: 24px;
  --sub1-font-weight: 500;
  --sub1-letter-spacing: normal;
  --sub1-font-style: normal;
  --sub1-text-transform: uppercase;

  // subtitle-2
  --sub2-font-size: 12px;
  --sub2-line-height: normal;
  --sub2-font-weight: 500;
  --sub2-letter-spacing: 0.15px;
  --sub2-font-style: normal;
  --sub2-text-transform: uppercase;

  // button-1
  --button1-font-size: 15px;
  --button1-line-height: normal;
  --button1-font-weight: 700;
  --button1-letter-spacing: normal;
  --button1-font-style: italic;
  --button1-text-transform: uppercase;

  // button-2
  --button2-font-size: 13px;
  --button2-line-height: normal;
  --button2-font-weight: 700;
  --button2-letter-spacing: normal;
  --button2-font-style: italic;
  --button2-text-transform: uppercase;

  // caption
  --caption-font-size: 15px;
  --caption-line-height: normal;
  --caption-font-weight: 500;
  --caption-letter-spacing: normal;
  --caption-font-style: normal;
  --caption-text-transform: none;

  // overline
  --overline-font-size: 12px;
  --overline-line-height: 16px;
  --overline-font-weight: 500;
  --overline-letter-spacing: normal;
  --overline-font-style: normal;
  --overline-text-transform: none;

  // nav-1
  --nav1-font-size: 18px;
  --nav1-line-height: normal;
  --nav1-font-weight: 500;
  --nav1-letter-spacing: normal;
  --nav1-font-style: normal;
  --nav1-text-transform: none;

  // nav-2
  --nav2-font-size: 16px;
  --nav2-line-height: 24px;
  --nav2-font-weight: 500;
  --nav2-letter-spacing: normal;
  --nav2-font-style: normal;
  --nav2-text-transform: none;

  @include app.device('small') {
    // heading-1
    --h1-font-size: 40px;
    --h1-line-height: 42px;

    // heading-2
    --h2-font-size: 26px;
    --h2-line-height: 30px;
    --h2-letter-spacing: normal;

    // heading-3
    --h3-font-size: 24px;
    --h3-line-height: 28px;
    --h3-font-weight: 700;

    // heading-4
    --h4-font-size: 22px;
    --h4-line-height: 26px;

    // heading-7
    --h7-font-size: 16px;

    // nav-1
    --nav1-font-size: 19px;
    --nav1-line-height: normal;
    --nav1-font-weight: 750;
    --nav1-letter-spacing: -0.3px;
    --nav1-font-style: italic;
    --nav1-text-transform: uppercase;
  }
}
